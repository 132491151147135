import QrScanner from 'qr-scanner';

window.addEventListener('turbolinks:load', () => {
  const video = document.querySelector('video')
  if (video === null) {
    return
  }

  var qrScanner = null

  const success = (result) => {
    qrScanner.stop();
    location.href = result + `?type=${video.dataset.type}`
  }

  if (video !== null) {
    qrScanner = new QrScanner(
      video,
      success,
    )
    qrScanner.start()
  }
})