window.addEventListener('turbolinks:load', () => {
  const geoButton = document.querySelector(".js-geo")

  if (geoButton === null) {
    return
  }

  geoButton.addEventListener('click', (e) => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords
      location.href = `/tumbler?latitude=${latitude}&longitude=${longitude}`
    })
  })
})