window.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.js-toggle').forEach((element) => {
    const id = element.dataset.id
    const clickEventType = (window.ontouchstart !== null) ? "click" : "touchend"
    element.addEventListener(clickEventType, (e) => {
      const opener = document.querySelector(`.js-toggle-${id}`)
      const direction = document.querySelector(`.js-toggle-direction-${id}`)
      opener.classList.toggle('hidden')
      direction.textContent = opener.classList.contains('hidden') ? "▶" : "▼"
    })
  })
})