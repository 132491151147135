window.addEventListener('turbolinks:load', () => {
  const stampModalDom = document.querySelector('#stamp')

  if (stampModalDom !== null) {
    const closeButton = document.querySelector(".js-click-modal-close")
    closeButton.addEventListener('click', event => {
      stampModalDom.remove()
    })
  }
})
