window.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.js-cup-toggle').forEach((element) => {
    const clickEventType = (window.ontouchstart !== null) ? "click" : "touchend"
    element.addEventListener(clickEventType, (e) => {
      e.target.classList.toggle('bg-black')
      e.target.classList.toggle('text-white')
      e.target.classList.toggle('bg-white')
      e.target.classList.toggle('text-black')
      e.target.classList.toggle('checked')
    })
  })
})