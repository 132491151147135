window.addEventListener('turbolinks:load', () => {
  const dateField = document.querySelector('.js-date-picker')

  if(dateField !== null) {
    const syncLabel = document.querySelector(".js-date-sync-label")
    dateField.addEventListener("change", (e) => {
      console.log(e.target.value)
      syncLabel.textContent = e.target.value.replace(/-/g, "/") || "2021-11-11"
    })
  } 
})