window.addEventListener('turbolinks:load', () => {
  const elements = document.querySelectorAll(".js-qrcode")
  console.log(`element start ${elements.length}`)
  elements.forEach((element) => {
    new QRCode(element, {
      text: element.dataset.url,
      width: 128,
      height: 128,
      colorDark : "#000000",
      colorLight : "#ffffff",
      correctLevel : QRCode.CorrectLevel.H
    });
  })
})