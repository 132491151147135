window.addEventListener('turbolinks:load', () => {
  const menuButton = document.querySelector('.js-menu_button')
  const closeButton = document.querySelector('.js-menu_close')

  if (menuButton === null || closeButton === null) {
    return
  }

  menuButton.addEventListener('click', (e) => {
    const menu = document.querySelector('.js-menu')
    menu.classList.remove('hidden')
    menu.classList.remove('w-1/5')
    menu.classList.add("w-full")
    menu.classList.add("fixed")
    menu.classList.add("top-14")
  })

  closeButton.addEventListener('click', (e) => {
    const menu = document.querySelector('.js-menu')
    menu.classList.add('hidden')
  })
})