window.addEventListener('turbolinks:load', () => {
  const hides = document.querySelectorAll('.js-hide-element')
  const displayLink = document.querySelector('#display-link')
  if (hides == null || displayLink == null) return

  hides.forEach((element) => {
    element.classList.add("hidden")
  })

  displayLink.addEventListener('click', () => {
    hides.forEach((element) => {
      element.classList.remove("hidden")
    })
    displayLink.remove()
  })
})