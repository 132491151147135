import consumer from "./consumer"

window.addEventListener('turbolinks:load', () => {
  const element = document.querySelector(".js-channel")
  if (element === null) {
    return
  }

  consumer.subscriptions.create({ channel: "CupsChannel", shop_id: element.dataset.shopId }, {
    received(_) {
      const found = document.querySelectorAll('.js-cup-toggle.checked').length > 0
      if (found) {
        document.querySelector('.js-reload-alert').classList.remove('hidden')
      } else {
        location.reload()
      }
    }
  })
})