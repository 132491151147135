import { Loader } from "@googlemaps/js-api-loader"
import styles from './maps/style.json';

window.addEventListener('turbolinks:load', () => {
  const defaultLatitude = 24.452077521309835
  const defaultLongitude = 124.22591074701235
  const centerIcon = "https://res.cloudinary.com/hijf5w17w/image/upload/v1644674639/icon/center_abt8uu.png"
  const loader = new Loader({ apiKey: "AIzaSyCclhe90kPI1-2weoqokjrG0oipQ7OOFkA" })

  const mapDiv = document.querySelector('.js-map')
  if(mapDiv == null) {
    return
  }

  const center = {
    lat: parseFloat(mapDiv.dataset.latitude || defaultLatitude),
    lng: parseFloat(mapDiv.dataset.longitude || defaultLongitude)
  }

  const isCurrent = mapDiv.dataset.latitude !== undefined
  const zoom = isCurrent ? 12 : 8

  loader.load().then(() => {

    const map = new google.maps.Map(mapDiv, {
      center: center,
      zoom: zoom,
      styles: styles,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
    })

    if (isCurrent) {
      new google.maps.Marker({
        position: center,
        map: map,
        icon: centerIcon
      });
    }

    const infoWindow = new google.maps.InfoWindow();

    document.querySelectorAll('.js-map-marker').forEach((element) => {
      const location = { lat: parseFloat(element.dataset.latitude), lng: parseFloat(element.dataset.longitude) }
      const title = element.dataset.title

      const marker = new google.maps.Marker({
        position: location,
        map: map,
        title: title
      });

      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setContent(marker.getTitle());
        infoWindow.open(marker.getMap(), marker);
      })
    })
  })
})
