window.addEventListener('turbolinks:load', () => {
  const rentalField = document.querySelector('.js-rental-number')
  const submit = document.querySelector('.js-rental-submit')

  if (rentalField === null || submit === null) {
    return
  }

  rentalField.addEventListener('change', (e) => {
    submit.setAttribute('href', `/cups/${rentalField.value}/rent`)
  })
})